<template>
  <v-container
    id="login"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="4"
        md="4"
      >
        <base-material-card
          color="blue-grey darken-4"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Login
              </h1>

              <div class="subtitle-1 font-weight-light">
                BlackBox
              </div>
            </div>
          </template>

          <v-form
            @submit.prevent="handleLogin"
          >
            <v-card-text class="text-center">
              <v-text-field
                v-model="login.email"
                prepend-icon="mdi-face"
                placeholder="Email"
              />
              <v-text-field
                v-model="login.password"
                placeholder="Password"
                prepend-icon="mdi-lock-outline"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-1"
                @click:append="showPassword = !showPassword"
              />
              <v-btn
                class="mx-2 text-center blue-grey darken-4"
                fab
                large
                type="submit"
              >
                <v-icon
                  dark
                >
                  mdi-fingerprint
                </v-icon>
              </v-btn>
              <v-text-field
                v-if="loading"
                loading
                disabled
              />
            </v-card-text>
          </v-form>
        </base-material-card>
        <v-alert
          :value="alert"
          :type="type"
          transition="scale-transition"
        >
          {{ alertText }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Login',
    data () {
      return {
        loading: false,
        message: '',
        alertText: null,
        type: null,
        showPassword: false,
        alert: false,
        login: {
          email: '',
          password: '',
        },
      }
    },
    computed: {
      loggedIn () {
        return this.$store.state.auth.status.loggedIn
      },
    },
    created () {
      if (this.loggedIn) {
        this.$router.push('/')
      }
    },
    methods: {
      showAlert (type, text) {
        this.type = type
        this.alertText = text
        this.alert = true
      },
      handleLogin () {
        this.alert = false
        this.loading = true
        if (this.login.email && this.login.password) {
          this.$store.dispatch('auth/login', this.login).then(
            () => {
              this.$router.push('/')
            },
            error => {
              this.loading = false
              const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString()
              const alertType = (error.response && error.response.data && error.response.data.type) || 'error'
              this.showAlert(alertType, message)
            },
          )
        }
      },
    },
  }
</script>
