<template>
  <v-app>
    <div class="login">
      <login />
    </div>
  </v-app>
</template>

<script>
  import login from '@/components/auth/Login'
  export default {
    name: 'PagesLogin',

    components: {
      login,
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
